import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPersonalSelector } from 'src/selectors/personal-selector';

import { getPersonalCounters, getPersonalData } from '@actions/personal/personal';
import { isDecimal, pluralizeReviews, svgStarPath, userRole } from '@constants';

import SpecialistVerification from './SpecialistVerification/SpecialistVerification';

import './desktop-sidebar.scss';

const StarRatings = dynamic(() => import('react-star-ratings'), { ssr: false });

const DesktopSidebar = () => {
    const { asPath } = useRouter();

    const { basicData, counters } = useSelector(getPersonalSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPersonalData());
        dispatch(getPersonalCounters());
    }, [dispatch]);

    const isSpecialist = useMemo(() => basicData.type_key === userRole.specialist, [basicData.type_key]);
    const isClient = useMemo(() => basicData.type_key === userRole.client, [basicData.type_key]);

    const routes = useMemo(
        () => [
            {
                name: 'Рабочий стол',
                href: '/site/personal-page/workspace',
                as: '/personal/workspace',
                count: 0,
                count_unview: 0,
            },
            {
                name: 'Мои дела',
                href: '/site/personal-page/my-cases',
                as: '/personal/my-cases',
                count: counters?.legal_cases?.count,
                count_unview: counters?.legal_cases?.count_unview,
            },
            {
                name: 'Документы',
                href: '/site/personal-page/documents',
                as: '/personal/documents',
                count: counters?.documents?.count,
                count_unview: counters?.documents?.count_unview,
            },
            {
                name: isClient ? 'Календарь' : 'Календарь занятости',
                href: '/site/personal-page/employment-calendar',
                as: '/personal/employment-calendar',
                count: 0,
                count_unview: 0,
            },
            {
                name: 'Заявки',
                href: '/site/personal-page/orders',
                as: '/personal/orders',
                count: counters?.orders?.count,
                count_unview: counters?.orders?.count_unview,
            },
            {
                name: 'Мой профиль',
                href: '/site/personal-page/my-profile',
                as: '/personal/my-profile',
                count: 0,
                count_unview: 0,
            },
            {
                name: 'Уведомления',
                href: '/site/personal-page/notifications-page',
                as: '/personal/notifications',
                count: 0,
                count_unview: 0,
            },
            {
                name: 'Безопасность',
                href: '/site/personal-page/security',
                as: '/personal/security',
                count: 0,
                count_unview: 0,
            },
            {
                name: 'Банковские карты и счета',
                href: '/site/personal-page/cards',
                as: '/personal/cards',
                count: 0,
                count_unview: 0,
            },
            {
                name: 'Связанные аккаунты',
                href: '/site/personal-page/accounts',
                as: '/personal/accounts',
                count: 0,
                count_unview: 0,
            },
        ],
        [counters],
    );

    const rating = useMemo(
        () => (isDecimal(basicData.specialist_data?.rating) ? basicData.specialist_data?.rating?.toFixed(1) : basicData.specialist_data?.rating),
        [basicData?.specialist_data?.rating],
    );

    return (
        <div className='admin-sidebar'>
            {isSpecialist && (
                <div className='admin-sidebar__user'>
                    <h4 className='admin-sidebar__user-name'>{`${basicData.first_name} ${basicData?.middle_name ?? ''} ${basicData.last_name}`}</h4>
                    <p className='admin-sidebar__user-role'>{basicData?.specialist_data?.category_specialist?.label}</p>
                    <div className='admin-sidebar__rating-wrap'>
                        <div className='admin-sidebar__rating-inner'>
                            <StarRatings
                                numberOfStars={1}
                                rating={rating / 5}
                                starDimension='20px'
                                starSpacing=''
                                starEmptyColor='#DCDEE4'
                                starRatedColor='#F8E5B8'
                                svgIconPath={svgStarPath}
                            />
                            <span className='admin-sidebar__rating'>{rating}</span>
                        </div>
                        {!!basicData.specialist_data.review_count && (
                            <>
                                <span className='admin-sidebar__rating-dot' />
                                <span className='admin-sidebar__reviews'>{pluralizeReviews(basicData.specialist_data.review_count)}</span>
                            </>
                        )}
                    </div>
                    <SpecialistVerification />
                </div>
            )}

            <div className='admin-sidebar__menu'>
                {routes.map(({ name, href, as, count, count_unview }) =>
                    asPath.includes(as) ? (
                        <Link key={href} href={href} as={as}>
                            <a>
                                <div className='admin-sidebar__menu-item admin-sidebar__menu-item_active'>
                                    {name} {count ? <span>{count}</span> : null}{' '}
                                    {count_unview ? <div className='admin-sidebar__menu-item_unview'>{count_unview}</div> : null}
                                </div>
                            </a>
                        </Link>
                    ) : (
                        <Link key={href} href={href} as={as}>
                            <a>
                                <div className='admin-sidebar__menu-item'>
                                    {name} {count ? <span>{count}</span> : null}
                                    {count_unview ? <div className='admin-sidebar__menu-item_unview'>{count_unview}</div> : null}
                                </div>
                            </a>
                        </Link>
                    ),
                )}
            </div>
            <hr className='admin-sidebar__line' />
            <div className='admin-sidebar__links'>
                <Link href='' as=''>
                    <a className='admin-sidebar__link'>Как поднять свой рейтинг?</a>
                </Link>
                <Link href='' as=''>
                    <a className='admin-sidebar__link'>Участвуйте в развитии ресурса</a>
                </Link>
                <Link href='' as=''>
                    <a className='admin-sidebar__link'>Как правильно обрабатывать заявки?</a>
                </Link>
            </div>
        </div>
    );
};

export default DesktopSidebar;
